<template>
    <div>
        <el-row>
            <el-form v-model="params" :inline="true" label-position="right" label-width="110px" size="mini">
                <el-form-item label="服务号名称" prop="name">
                    <el-input v-model="params.name"></el-input>
                </el-form-item>
                <el-form-item label="状态" prop="status">
                    <el-select v-model="params.status">
                        <el-option value="" label="全部"></el-option>
                        <el-option v-for="item in statusList" :value="item.value" :label="item.label"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="doSearch">搜索</el-button>
                    <el-button icon="el-icon-refresh" @click="resetQuery">重置</el-button>
                </el-form-item>
            </el-form>
        </el-row>
        <el-row>
            <el-col :span="18">
                <el-button type="primary" icon="el-icon-plus" size="mini" v-permission="'waiter:services:add'" @click="handleAdd">新增</el-button>
            </el-col>
            <el-col :span="6">

            </el-col>
        </el-row>
        <el-table :data="dataList" style="width: 100%" stripe fit v-loading="loading">
            <el-table-column align="center" label="服务号" prop="servicesNo"></el-table-column>
            <el-table-column align="center" label="服务号名称" prop="name"></el-table-column>
            <el-table-column align="center" label="状态" prop="status">
                <template v-slot="scope">
                    <span v-for="item in statusList" v-if="scope.row.status === item.value">{{ item.label }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="创建人" prop="createBy"></el-table-column>
            <el-table-column align="center" label="创建时间" prop="createTime"></el-table-column>
            <el-table-column align="center" label="操作" fixed="right">
                <template v-slot="scope">
                    <el-button type="text" size="mini" icon="el-icon-edit-outline" v-permission="'waiter:services:edit'" @click="handleUpdate(scope.row)">
                        修改
                    </el-button>
                    <el-button type="text" size="mini" icon="el-icon-video-pause" v-permission="'waiter:services:disable'" v-if="scope.row.status === 0"
                               @click="handleDisable(scope.row)">禁用
                    </el-button>
                    <el-button type="text" size="mini" icon="el-icon-video-play" v-permission="'waiter:services:enable'" v-if="scope.row.status === 9"
                               @click="handleEnable(scope.row)">启用
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
                @size-change="findPage"
                @current-change="findPage"
                :current-page="params.current"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="params.size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
        </el-pagination>

        <el-dialog :title="dataFromAdd?'添加数据':'修改数据'" :visible.sync="dataFromSync" width="400px">
            <el-form :model="dataFrom" :rules="dataFromRules" ref="dataFrom" label-width="100px" size="medium">
                <el-form-item label="服务号" prop="servicesNo">
                    <el-input v-model="dataFrom.servicesNo"></el-input>
                </el-form-item>
                <el-form-item label="服务号名称" prop="name">
                    <el-input v-model="dataFrom.name"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" size="medium" @click="submitForm('dataFrom')">确 定</el-button>
                <el-button size="medium" @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import * as servicesApi from "@/api/waiter/waiterServices.js"

export default {
    name: "WaiterServices",
    data() {
        return {
            statusList: [{label: '正常', value: 0}, {label: '禁用', value: 9}],
            dataFrom: {},
            dataFromRules: {
                servicesNo: [{required: true, message: '请输入服务号', trigger: 'blur'}],
                name: [{required: true, message: '请输入服务号名称', trigger: 'blur'}],
            },
            dataFromSync: false,
            dataFromAdd: true,
            params: {
                current: 1,
                size: 10,
                status: 0,
            },
            loading: false,
            dataList: [],
            total: 0,
            idList: []
        }
    },
    mounted() {
        this.findPage()
    },
    methods: {
        /**
         * 启用
         */
        handleEnable(row) {
            let ids = (row.servicesId ? [row.servicesId] : null) || this.idList
            if (ids) {
                servicesApi.handleEnable(ids).then(res => {
                    if (res.success) {
                        this.$message({
                            type: "success",
                            message: '保存成功！'
                        });
                        this.findPage()
                    } else {
                        this.$message({
                            type: "error",
                            message: res.msg
                        });
                    }
                })
            }
        },
        /**
         * 禁用
         */
        handleDisable(row) {
            let ids = (row.servicesId ? [row.servicesId] : null) || this.idList
            if (ids) {
                servicesApi.handleDisable(ids).then(res => {
                    if (res.success) {
                        this.$message({
                            type: "success",
                            message: '保存成功！'
                        });
                        this.findPage()
                    } else {
                        this.$message({
                            type: "error",
                            message: res.msg
                        });
                    }
                })
            }
        },
        /**
         * 取消
         */
        cancel() {
            this.dataFromSync = false
        },
        /**
         * 保存
         */
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    servicesApi.saveData(this.dataFrom).then(res => {
                        if (res.success) {
                            this.$message({
                                type: "success",
                                message: '保存成功！'
                            });
                            this.dataFromSync = false
                            this.findPage()
                        } else {
                            this.$message({
                                type: "error",
                                message: res.msg
                            });
                        }
                    })
                } else {
                    return false;
                }
            });
        },
        /**
         * 修改
         * @param row
         */
        handleUpdate(row) {
            this.dataFromSync = true
            this.dataFromAdd = false
            this.dataFrom = Object.assign({}, row)
        },
        /**
         * 添加
         */
        handleAdd() {
            this.dataFromSync = true
            this.dataFromAdd = true
            this.dataFrom = {}
        },
        /**
         * 重置
         */
        resetQuery() {
            this.params = {
                current: 1,
                size: 10,
                status: 0,
            }
        },
        /**
         * 点击搜索
         */
        doSearch() {
            this.params.current = 1
            this.findPage()
        },
        /**
         * 分页查询数据
         */
        findPage() {
            this.loading = true
            servicesApi.findPage(this.params).then(res => {
                this.loading = false
                if (res.success) {
                    this.dataList = res.data.records
                    this.total = res.data.total
                }
            })
        },
    }
}
</script>

<style scoped>
.el-pagination {
    bottom: 10px;
    position: fixed;
}
</style>